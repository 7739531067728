@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}



::-webkit-scrollbar {
	width: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
	background-color: #256286;
	border-radius: 8px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
	background-color: #256286;
  }
  
  ::-webkit-scrollbar:horizontal {
	height: 2px;
  }
  * {
	scrollbar-width: thin;
	scrollbar-color: #256286 #f5f5f5;
  }
  
  *:hover {
	scrollbar-color: #256286 #f5f5f5;
  }
  